import { EMPTY_FUNCTION } from '@cognitiv/cassiopeia-ui';
import { ORION_CAMPAIGN_TYPE, ORION_CAMPAIGN_TYPE_NAME } from 'products/orion/modals/enums';
import { OrionModals } from 'products/orion/modals/types';
import { ORION_DATA_CENTER_ID } from 'products/orion/operators/data-centers/constants';

export const manage_orion_advertiser_domain_list_default = {
  is_open: false,
  advertiser_id: null,
  advertiser_name: '',
  segment_id: null,
  segment_name: '',
  onSuccess: EMPTY_FUNCTION,
  onFailure: EMPTY_FUNCTION,
  error_instructions: '',
};

export const orion_modals: OrionModals = {
  manage_orion_campaign: { is_open: false, campaign_type: ORION_CAMPAIGN_TYPE.CONTEXUTAL },
  create_orion_deals: { is_open: false, data_center_id: ORION_DATA_CENTER_ID.US },
  manage_orion_advertiser_list: { is_open: false },
  manage_orion_segment_domain_list: { is_open: false },
  update_orion_segment: { is_open: false },
  create_orion_segment: { is_open: false },
  create_ai_generated_prompts: { is_open: false },
  manage_orion_advertiser_domain_list: { ...manage_orion_advertiser_domain_list_default },
  select_orion_advertiser_domain_list: { is_open: false },
  manage_orion_deal_note: { is_open: false },
  update_orion_deal: { is_open: false },
};

export const orion_campaign_type_options = [
  {
    label: ORION_CAMPAIGN_TYPE_NAME.CONTEXUTAL,
    value: ORION_CAMPAIGN_TYPE.CONTEXUTAL,
  },
  {
    label: ORION_CAMPAIGN_TYPE_NAME.CORE_CONTEXUTAL,
    value: ORION_CAMPAIGN_TYPE.CORE_CONTEXUTAL,
  },
];
